
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import CompanyContact from '@/models/CompanyContact'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import moment from 'moment'

@Component({
  components: {
    FormInput,
    IconAction,
    CompanyPicker,
    UserPicker,
    SelectPicker,
  },
})
export default class CompanyContactForm extends ViewModel {
  @Prop()
  public value!: any

  @Prop({ default: false })
  public disabled!: boolean

  public loading: boolean = false

  public local_value: CompanyContact = new CompanyContact()

  public getToday: any = moment()

  @Watch('local_value')
  public localValueOnChange(val: any) {
    if (val != null) this.$emit('input', val)
  }

  public created() {
    this.local_value = this.value

    if (!this.local_value.owner_id) {
      this.local_value.owner_id = this.user.id
    }

    this.loading = false
  }

  public removeContact(index: number) {
    this.local_value.contacts.splice(index, 1)
  }

  public addContact() {
    this.local_value.contacts.push({
      label: null,
      value: null,
    })
  }

  public get salutations() {
    return CompanyContact.salutations
  }
}
