
import { Component, Prop, Watch } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import UserForm from '@/pages/User/components/UserForm.vue'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'
import WebMessage from '@/models/WebMessage'

@Component({
  components: {
    SelectPicker,
    UserForm,
  },
})
export default class CustomPicker extends ViewModel {
  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop()
  public company_id!: string

  @Prop()
  public station_id!: string

  @Prop({ default: 30 })
  public duration!: number

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: 'User' })
  public label!: string

  @Prop({ default: 'any' })
  public type!: string

  @Prop({ default: () => [] })
  public value!: string[]

  @Prop({ default: false })
  public disabled!: boolean

  public local_value: string[] = []

  public new_id: string = ''

  public show_sidebar: boolean = false

  public update: boolean = true

  public uuid: string = ''

  public busy: boolean = false

  public ready: boolean = false

  public options: SelectOption[] = []

  public label_name: string = 'N/A'

  public custom_picker_options: Array<any> = []

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
      this.ready = true
    } else {
      this.busy = true
      this.value.forEach((v: any) => {
        if (!this.options.some((o: any) => o.value === v)) {
          this.custom_picker_options.push({
            name: v,
            value: v,
          })
        }
      })
      this.local_value = this.value
      this.busy = false
      this.ready = false
    }
  }

  public addTag(newTag: string) {
    if (this.type === 'email') {
      if (this.validateEmail(newTag)) {
        this.confirmAddTag(newTag)
      } else {
        WebMessage.error('Please enter a valid email.')
      }
    } else {
      this.confirmAddTag(newTag)
    }
  }

  private confirmAddTag(newTag: string) {
    const tag = {
      name: newTag,
      value: newTag,
    }
    this.custom_picker_options.push(tag)
    this.local_value.push(newTag)
  }

  public validateEmail(email: string) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return res.test(email.toLowerCase())
  }

  @Watch('local_value')
  public onChangeValue() {
    this.update = false
    this.$emit('input', this.local_value)
  }

  @Watch('value')
  public onChangeProp() {
    if (this.update) {
      if (Array.isArray(this.value)) {
        this.value.forEach((v: any) => {
          if (!this.options.some((o: any) => o.value === v)) {
            this.custom_picker_options.push({
              name: v,
              value: v,
            })
          }
        })
      }
      this.local_value = this.value
    }
    this.update = true
  }
}
