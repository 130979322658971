
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import Api from '@/models/Api'
import Inventory from '@/models/Inventory'
import CloseTag from '../CloseTag/CloseTag.vue'

@Component({
  components: {
    FormInput,
    IconAction,
    CloseTag,
  },
})
export default class AdunitSelector extends ViewModel {
  @Prop()
  public included!: any

  @Prop()
  public excluded!: any

  @Prop()
  public adserver!: string

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: 'adunit_selector' })
  public id!: string

  @Prop({ default: 'Adunit' })
  public name!: string

  @Prop({ default: null })
  public rules: any

  public selected_nodes: any = []

  public inventory_list: any = []

  public filter: any = ''

  public load_tracker: any = {}

  public loading: boolean = true

  public backend_searching: boolean = false

  public ready: boolean = false

  public emitErrors(errors: any) {
    this.$emit('errors', errors)
    return ''
  }

  public get someValues() {
    return (
      (this.included && this.included.length > 0) || (this.excluded && this.excluded.length > 0)
    )
  }

  @Watch('disabled')
  public onDisabledChange(val: any) {
    if (val) {
      this.$emit('update:edit', false)
    } else {
      this.$emit('update:edit', true)
    }
  }

  public mounted() {
    this.loadData()

    setTimeout(() => {
      // this.onSomeValuesChange(this.someValues)
      this.ready = true
    }, 500)
  }

  // watch filter
  @Watch('filter')
  public onFilterChange(val: any) {
    if (val && val.length) {
      this.collapseAll()
      // search for this
      this.filterName(val)
    }
  }

  // top level inventory
  public get inventoryList() {
    let search_terms = this.filter.toLocaleLowerCase().split(' ')
    let ret = this.inventory_list.filter((i: any) => {
      if (search_terms.length > 0 && this.filter != '') {
        return search_terms.every((term: any) => i.name.toLocaleLowerCase().includes(term))
      }
      return !i.parent_id
    })
    return ret
  }

  public filterName(val: any) {
    Inventory.searchByName({ value: val }).then((res: any) => {
      this.backend_searching = true
      let ids: string[] = []
      let new_list: any = []

      let base = [...new Set([...this.inventory_list, ...res])]
      base.forEach((i: any) => {
        if (!ids.includes(i.id)) {
          ids.push(i.id)
          new_list.push(i)
        }
      })

      Vue.set(
        this,
        'inventory_list',
        new_list.sort((a: any, b: any) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        }),
      )
      res.forEach((i: any) => {
        Vue.set(this.load_tracker, i.id, this.setDemandObj(i.id))
      })
      this.backend_searching = false
    })
  }

  public select(node: any, include: boolean = true) {
    if (!this.edit) return
    let index = this.selected_nodes.findIndex((s: any) => s.id === node.id)
    let _action = include ? 'include' : 'exclude'
    let obj = {
      ...node,
      action: _action,
    }

    if (index > -1) {
      Vue.set(this.selected_nodes[index], 'action', _action)
    } else {
      this.selected_nodes.push(obj)
    }

    this.emit()
  }

  public removeAll() {
    this.selected_nodes = []
    this.emit()
  }

  public remove(index: number) {
    if (!this.edit) return
    if (!this.edit) return
    this.selected_nodes.splice(index, 1)
    this.emit()
  }

  public isSelected(node: any) {
    return this.selected_nodes.some(sn => sn.name === node.name)
  }

  public isIncluded(node: any) {
    return this.selected_nodes.some(sn => sn.id === node.id && sn.action === 'include')
  }

  public isExcluded(node: any) {
    return this.selected_nodes.some(sn => sn.id === node.id && sn.action === 'exclude')
  }

  public emit() {
    setTimeout(() => {
      let include = this.selected_nodes.filter(sn => sn.action === 'include')
      let exclude = this.selected_nodes.filter(sn => sn.action === 'exclude')

      const map_values = (arr: any) => arr.map((n: any) => n.id)

      this.$emit('update:included', map_values(include))
      this.$emit('update:excluded', map_values(exclude))
    }, 400)
  }

  public setDemandObj(id: string) {
    return {
      loading: true,
      open_child: false,
      childs: null, // [ids] later use these ids to remove all loaded childs from all parents
    }
  }

  public collapseAll() {
    let updated_obj: any = {}
    for (let i in this.load_tracker) {
      updated_obj[i] = {
        ...this.load_tracker[i],
        open_child: false,
      }
    }

    Vue.set(this, 'load_tracker', updated_obj)
  }

  public loadData() {
    this.loading = true

    Inventory.loadInventories({
      id: null,
      selected_inventory_ids: [...this.included, ...this.excluded],
    }).then(inventories => {
      this.inventory_list = inventories.inventories.sort((a: any, b: any) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      this.loadAdUnits(inventories)
      this.inventory_list.forEach((i: any) => {
        Vue.set(this.load_tracker, i.id, this.setDemandObj(i.id))
      })

      this.loading = false
    })
  }

  public loadAdUnits(inventories: any) {
    this.included.forEach((inventory: any) => {
      let node = inventories.selected_inventories.find((i: any) => i.id === inventory)
      if (node) {
        this.select(node)
      }
    })
    this.excluded.forEach((inventory: any) => {
      let node = inventories.selected_inventories.find((i: any) => i.id === inventory)
      if (node) {
        this.select(node, false)
      }
    })
  }

  public open_child(inventory_id: any) {
    this.$root.$emit('bv::toggle::collapse', `child-${inventory_id}`)
    // load child

    const load = () => {
      Inventory.loadInventories({
        id: inventory_id,
      }).then(inventories => {
        if (inventories.inventories.length) {
          this.inventory_list = [
            ...new Set([...this.inventory_list, ...inventories.inventories]),
          ].sort((a: any, b: any) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          Vue.set(this.load_tracker[inventory_id], 'childs', [
            ...new Set(inventories.inventories.map((i: any) => i.id)),
          ])
        }
        setTimeout(() => {
          Vue.set(this.load_tracker[inventory_id], 'loading', false)
          Vue.set(this.load_tracker[inventory_id], 'open_child', true)
        }, 200)
      })
    }

    if (this.load_tracker.hasOwnProperty(inventory_id)) {
      // check if childs are loaded
      if (this.load_tracker[inventory_id].childs && this.load_tracker[inventory_id].childs.length) {
        let state = this.load_tracker[inventory_id].open_child
        Vue.set(this.load_tracker[inventory_id], 'open_child', !state)
      } else {
        load()
      }
    } else {
      Vue.set(this.load_tracker, inventory_id, this.setDemandObj(inventory_id))
      load()
    }
  }

  public get_child(inventory_id: any) {
    let childs = Array.from(
      this.inventory_list
        .reduce((uniqueItems, item) => uniqueItems.set(item.name, item), new Map())
        .values(),
    )

    return childs.filter((i: any) => i.parent_id === inventory_id && i.id !== inventory_id)
  }

  public removeSelectionById(id: string) {
    const index = this.selected_nodes.findIndex((s: any) => s.id === id)
    if (index > -1) {
      this.remove(index)
      return true
    }
    return false
  }
}
