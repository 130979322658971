
import { Component, Prop, Watch } from 'vue-property-decorator'
import User from '@/models/User'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import ProfilePicker from '@/components/ProfilePicker/ProfilePicker.vue'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ReportViewPicker from '@/components/ReportViewPicker/ReportViewPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import RegionPicker from '@/components/RegionPicker/RegionPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import moment from 'moment'
import Company from '@/models/Company'

@Component({
  components: {
    CompanyPicker,
    FormInput,
    ProfilePicker,
    SelectPicker,
    ReportViewPicker,
    DatePicker,
    RegionPicker,
    UserPicker,
  },
})
export default class UserForm extends ViewModel {
  @Prop({ default: '' })
  public id!: string

  public type_options: object[] = [
    { name: 'System', value: 'system' },
    { name: 'Root', value: 'root' },
    { name: 'Api', value: 'api' },
    { name: 'Sales', value: 'sales' },
    { name: 'Default', value: 'default' },
    { name: 'Group', value: 'group' },
  ]

  public department_list: object[] = [
    { name: 'Default', value: null },
    { name: 'Sales', value: 'sales' },
    { name: 'AdOps', value: 'ad_ops' },
    { name: 'Planners / Account Manager', value: 'account_managers' },
    { name: 'Finance', value: 'finance' },
  ]

  public target: User = new User()

  public busy: boolean = true

  public block_mfa_date: boolean = false

  public loading_company: boolean = false

  @Prop({
    default: 'full',
  })
  public view_mode!: string

  @Prop({
    default: '',
  })
  public new_id!: string

  @Prop({
    default: true,
  })
  public show_sidebar!: boolean

  @Prop({ default: '' })
  public company_id!: string

  @Prop({ default: '' })
  public station_id!: string

  @Watch('show_sidebar')
  public onSidebarStatus() {
    this.init()
  }

  @Watch('company_id')
  public onCompanyChange() {
    this.target.company_id = this.company_id
  }

  @Watch('station_id')
  public onStationChange() {
    this.target.company_id = this.company_id
    this.target.station_id = this.station_id
  }

  @Watch('target.company_id')
  public onTargetCompanyChange() {
    if (!this.id) this.target.station_id = null
  }

  @Watch('target.station_id')
  public onTargetStationChange() {
    this.target.profile_id = null
  }

  public get computedMFA() {
    return this.target.company.mfa_required
  }

  public init() {
    if (this.id) {
      User.find(this.id).then(o => {
        if (o instanceof User) {
          this.target = o
        } else {
          this.target = new User()
        }
        if (!this.target.mfa_required_at) {
          if (o.company.mfa_required) this.target.mfa_required_at = moment().add(15, 'days')
        }
        this.applyRestriction()
        this.busy = false
      })
    } else {
      this.target = new User()
      this.applyRestriction()
      this.busy = false
    }
  }

  public applyRestriction() {
    if (this.company_id) {
      this.target.company_id = this.company_id
    }

    if (this.station_id) {
      this.target.station_id = this.station_id
    }
  }

  public mounted() {
    this.init()
  }

  public onSubmit() {
    this.loading = true

    this.target
      .save()
      .then(response => {
        if (response.status == 200) {
          if (this.view_mode == 'sidebar') {
            this.$emit('update:new_id', response.data.result.user.id)
            this.init()
            this.$emit('update:show_sidebar', false)
          } else {
            this.$router.push({ name: 'Users' })
          }
        }
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    if (this.view_mode == 'sidebar') {
      this.$emit('update:show_sidebar', false)
    } else {
      this.$router.push({ name: 'Users' })
    }
  }

  public loadCompany() {
    if (this.loading_company) return
    this.loading_company = true
    setTimeout(() => {
      Company.find(this.target.company_id).then(response => {
        this.loading_company = false
        this.target.company = response
        if (response.mfa_required) {
          this.target.mfa_required_at = moment().add(15, 'days')
        }
      })
    }, 500)
  }
}
