
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ListBlock extends Vue {
  @Prop() label!: any

  @Prop() content!: any

  @Prop({ default: false }) hideHr!: any
}
