
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import CompanyContact from '@/models/CompanyContact'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import ContactAgenda from '@/components/CompanyContactPicker/ContactAgenda.vue'
import CompanyContactForm from '@/pages/CompanyContact/components/CompanyContactForm.vue'

@Component({
  components: {
    SelectPicker,
    IconAction,
    ContactAgenda,
    CompanyContactForm,
  },
})
export default class CompanyContactPicker extends ViewModel {
  @Prop() value!: any

  @Prop({ default: false }) public multiple!: boolean

  @Prop({ default: false }) public showDetails!: boolean

  @Prop({ default: false }) public edit!: boolean

  @Prop({ default: false }) public disabled!: boolean

  @Prop({ default: false }) public autoConfirm!: boolean

  @Prop({ default: 'page' }) public editLocation!: string

  @Prop() public companies!: any

  public selected_objects: any = []

  public uuid: string = ''

  public ready: boolean = false

  public viewList: boolean = false

  public busy: boolean = true

  public options: any = []

  public is_managing: boolean = false

  public last_state: any = null

  public open_side_form: boolean = false

  public temp_company_contact: CompanyContact | any = null

  public saving_contact: boolean = false

  // public local_value: any = null

  public temp_selection: any = null

  @Watch('local_value', { immediate: true })
  public onValueChange(val: any) {
    this.temp_selection = val
  }

  @Watch('open_side_form')
  public onSideForm(val: boolean) {
    if (val) {
      document.body.classList.add('overflow-hidden')
      this.footerActions('hide')
      // this.zIndexFix(-1)
    } else {
      document.body.classList.remove('overflow-hidden')
      this.footerActions('display')
      this.zIndexFix()
    }
  }

  @Watch('is_managing')
  public onIsManagingChange(val: boolean) {
    if (val) {
      this.searchByValues()
      this.last_state = this.value
    } else {
      this.temp_selection = null
    }
  }

  public get local_value() {
    return this.value
  }

  public set local_value(value) {
    this.$emit('input', value)
  }

  public zIndexFix(val = 1) {
    let tab_content: HTMLElement | any = document.querySelector('div.tab-content')
    if (!tab_content) return
    tab_content.style.zIndex = val
  }

  public footerActions(n: string) {
    const footer: HTMLElement | any = document.querySelector('footer.footer-nav')
    if (!footer) return
    const actions: any = {
      hide: () => footer.classList.add('d-none'),
      display: () => footer.classList.remove('d-none'),
    }
    actions[n]()
  }

  public getInitials(name) {
    const words = name.split(' ')
    let initials = ''

    for (let i = 0; i < words.length; i++) {
      const word = words[i]
      if (word.length > 0) {
        initials += word.charAt(0)
      }
    }

    return initials
  }

  public selectNewContact() {
    Vue.set(this, 'temp_selection', this.local_value)

    setTimeout(() => {
      this.is_managing = true
    }, 400)
  }

  public setSelected(data) {
    this.selected_objects.push(data)
    this.selected_objects = [...new Set(this.selected_objects)]

    setTimeout(() => {
      Vue.set(this, 'local_value', this.temp_selection)
    }, 500)
  }

  public removeSelected(data) {
    this.selected_objects = this.selected_objects.filter(s => s.value !== data.value)
  }

  public searchByValues(ignore = false) {
    CompanyContact.searchOptions({
      value: this.local_value,
      company_ids: !ignore ? this.companies : null,
    }).then(response => {
      this.options = []
      this.options = response
      this.busy = false
      this.ready = true
    })
  }

  public mounted() {
    this.uuid = this.randomUUID()

    if (this.local_value && this.local_value.length) {
      this.temp_selection = this.local_value
    }

    setTimeout(() => {
      if (this.local_value && this.local_value.length) {
        this.searchByValues()
      } else {
        this.busy = false
        this.ready = true
        // this.is_managing = true
      }

      this.last_state = { ...this.value }
    }, 500)
  }

  public open() {
    if (this.options.length < 2) this.searchOptions()
  }

  public confirmContact() {
    if (this.temp_selection) {
      this.local_value = [...(this.local_value ?? ''), ...this.temp_selection]
    }

    this.is_managing = false

    this.temp_selection = null
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    CompanyContact.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
      company_ids: this.companies,
    }).then(response => {
      this.options = response
      // unique options by value

      this.busy = false
      this.ready = true
    })
  }

  public cancelEdition() {
    this.open_side_form = false
    this.temp_company_contact = null
    this.temp_selection = null
  }

  public newContact() {
    this.open_side_form = true
    this.temp_company_contact = new CompanyContact()
  }

  public editContact(contact: any) {
    if (this.editLocation === 'page') {
      this.$router.push({ name: 'company-contact', params: { id: contact.id ?? contact.value } })
    } else {
      this.open_side_form = true
      this.temp_company_contact = Object.assign(new CompanyContact(), { ...contact })
    }
    this.is_managing = false
  }

  public updateContact() {
    this.busy = true
    let new_contact = !this.temp_company_contact.id || !this.temp_company_contact.id.length
    this.temp_company_contact.save().then(res => {
      if (this.multiple) {
        // this.local_value.push(res.data.result.id)
        // this.local_value = [...this.local_value, res.data.result.id]
        Vue.set(this, 'local_value', [...this.local_value, res.data.result.id])
      } else {
        this.local_value = res.data.result.id
      }

      if (new_contact) {
        this.options.push({
          name: res.data.result.name,
          value: res.data.result.id,
        })
      } else {
        this.options = this.options.map(o => {
          if (o.value === res.data.result.id) {
            o.name = res.data.result.name
          }
          return o
        })
      }

      this.busy = false
      this.is_managing = false
      this.saving_contact = false
      this.temp_selection = this.local_value

      setTimeout(() => {
        this.searchByValues(true)
      }, 1000)
      this.cancelEdition()
    })
  }
}
