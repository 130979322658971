import Api from './Api'
import CalendarEvent from './CalendarEvent'
import Model from './interface/Model'

export default class CalendarModule extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'calendar' as string | null,
      plural: 'calendar' as string | null,
    },
  }

  public id: string = ''

  public user_id: string = ''

  public accessRole: string = ''

  public timezone: string = ''

  public backgroundColor: string = ''

  public colorId: string = ''

  public foregroundColor: string = ''

  public hidden: boolean = false

  public google_calendar_id: string = ''

  public kind: string = ''

  public location: string = ''

  public primary: boolean = false

  public selected: boolean = false

  public summary: string = ''

  public summaryOverride: string = ''

  public description: string = ''

  public etag: string = ''

  public defaultReminders: any = {}

  public conferenceProperties: any = {}

  public notificationSettings: any = {}

  public last_sync_at: string = ''

  public created_at: string = ''

  public events: CalendarEvent[] = []

  public event_settings: any = {
    view_type: 'week', // 'in:month,week,day,list'
    start_date: null,
    end_date: null,
  }

  public get apiData(): any {
    return {
      id: this.id,
      user_id: this.user_id,
      accessRole: this.accessRole,
      timezone: this.timezone,
      backgroundColor: this.backgroundColor,
      colorId: this.colorId,
      foregroundColor: this.foregroundColor,
      hidden: this.hidden,
      google_calendar_id: this.google_calendar_id,
      kind: this.kind,
      location: this.location,
      primary: this.primary,
      selected: this.selected,
      summary: this.summary,
      summaryOverride: this.summaryOverride,
      description: this.description,
      etag: this.etag,
      defaultReminders: this.defaultReminders,
      conferenceProperties: this.conferenceProperties,
      notificationSettings: this.notificationSettings,
      last_sync_at: this.last_sync_at,
      created_at: this.created_at,
      events: this.events,
    }
  }

  // @ts-ignore
  public toObject(data: any): CalendarModule {
    let calendar = this.clone()
    calendar.id = data.id
    calendar.user_id = data.user_id
    calendar.accessRole = data.accessRole
    calendar.timezone = data.timezone
    calendar.backgroundColor = data.backgroundColor
    calendar.colorId = data.colorId
    calendar.foregroundColor = data.foregroundColor
    calendar.hidden = data.hidden
    calendar.google_calendar_id = data.google_calendar_id
    calendar.kind = data.kind
    calendar.location = data.location
    calendar.primary = data.primary
    calendar.selected = data.selected
    calendar.summary = data.summary
    calendar.summaryOverride = data.summaryOverride
    calendar.description = data.description
    calendar.etag = data.etag
    calendar.defaultReminders = data.defaultReminders
    calendar.conferenceProperties = data.conferenceProperties
    calendar.notificationSettings = data.notificationSettings
    calendar.last_sync_at = data.last_sync_at
    calendar.created_at = data.created_at
    calendar.events = data.events
    return calendar
  }

  public static async sync() {
    let api = new Api()
    return api.get('calendar/sync').then((response: any) => response)
  }

  public static async syncEvents() {
    let api = new Api()
    return api.get('calendar/sync-events').then((response: any) => response)
  }

  public static async getCalendar(event_settings: any = null) {
    let api = new Api()
    return api.get('calendar', event_settings).then((response: any) => response.data.result)
  }
}
