import { ImpressionModelOptions, Products } from '@/models/interface/Common'
import { integerMask, currencyMask } from '@/models/interface/Masks'
import MediaPlan from '@/models/MediaPlan'
import MediaPlanItem from '@/models/MediaPlanItem'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'

const { user } = getModule(SystemtModule)

export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle',
    thClass: 'align-middle text-center',
    show: true,
    type: 'checkbox',
    thStyle: { width: '3rem' },
  },
  {
    key: 'number',
    label: '#',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '2rem' },
  },
  {
    key: 'order_type',
    label: 'Type',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '2rem' },
    visible: (media_plan: MediaPlan) => media_plan.isLinear,
  },
  {
    key: 'name',
    label: 'Name',
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
  },
  {
    key: 'product',
    label: 'Product',
    type: 'picker',
    options: Products,
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
    visible: (media_plan: MediaPlan) => !media_plan.isLinear,
  },
  {
    key: 'formStartAt',
    label: 'Start',
    type: 'date',
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formEndAt',
    label: 'End',
    type: 'date',
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'days_of_week',
    label: 'Days',
    type: 'days_of_week',
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '8rem' },
    visible: (media_plan: MediaPlan) => media_plan.isLinear,
  },
  {
    key: 'formModel',
    label: 'Model',
    type: 'picker',
    options: ImpressionModelOptions,
    allow_edit: true,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
    visible: (media_plan: MediaPlan) => !media_plan.isLinear,
  },
  {
    key: 'formImpressions',
    label: 'Impressions',
    type: 'number',
    allow_edit: true,
    visible: (media_plan: MediaPlan) => !media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formSpotImpressions',
    label: 'Spot Impressions',
    type: 'number',
    allow_edit: true,
    visible: (media_plan: MediaPlan) => media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formGrossRate',
    label: 'Gross Rate',
    type: 'number',
    // mask: currencyMask,
    // prepend: '$',
    label_format: 'currency',
    allow_edit: (media_plan: MediaPlan) => media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formNetRate',
    label: 'Net Rate',
    type: 'number',
    // mask: currencyMask,
    // prepend: '$',
    label_format: 'currency',
    allow_edit: (media_plan: MediaPlan) => !media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formGrossCost',
    label: 'Total Gross Cost',
    type: 'number',
    allow_edit: (media_plan: MediaPlan) => media_plan.isLinear,
    // mask: currencyMask,
    // prepend: '$',
    label_format: 'currency',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'formNetCost',
    label: 'Total Net Cost',
    type: 'number',
    // mask: currencyMask,
    // prepend: '$',
    label_format: 'currency',
    allow_edit: (media_plan: MediaPlan) => !media_plan.isLinear,
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'projected_profitability',
    label: 'Projected Profitability',
    type: 'number',
    label_format: 'percentage',
    visible: () => user?.profile_id === '4ce1a57c-2e2c-11ea-92b6-0a84a8791ed2',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '10rem' },
  },
  {
    key: 'special_features',
    label: 'Special Features',
    type: 'special_features',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '14rem' },
    allow_edit: (_: MediaPlan, media_plan_item: MediaPlanItem) => media_plan_item.product === 'ssl',
  },
  {
    key: 'action',
    label: 'Actions',
    type: 'action',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '5rem' },
  },
]
