import { clone } from 'lodash'
import moment from 'moment'
import Model from './interface/Model'
import Api from './Api'
import WebMessage from './WebMessage'

// create interface for google calendar event

interface CalendarEventInterface {
  date: String | null
  dateTime: String | null
  timeZone: String | null
}

export default class CalendarEvent extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'calendar-events' as string | null,
      plural: 'calendar-events' as string | null,
    },
  }

  public name: string = ''

  public id: string | null = null

  public calendar_id: string = ''

  public attendeesOmitted: boolean = false

  public colorId: string | null = null

  public description: string = ''

  public endTimeUnspecified: boolean = false

  public etag: string | null = null

  public eventType: string = 'default'

  public guestsCanInviteOthers: boolean = true

  public guestsCanModify: boolean = false

  public guestsCanSeeOtherGuests: boolean = true

  public hangoutLink: string | null = null

  public htmlLink: string | null = null

  public iCalUID: string | null = null

  public google_calendar_event_id: string = ''

  public kind: string = ''

  public location: string | null = null

  public locked: boolean = false

  public privateCopy: boolean = false

  public recurrence: any = null

  public recurringEventId: string | null = null

  public sequence: number = 0

  public status: string = 'confirmed'

  public summary: string = ''

  public transparency: string = 'opaque'

  public updated: string | null = null

  public visibility: string = 'default'

  public creator: any | null = null

  public organizer: any | null = null

  private use_separated_tz: boolean = false

  private use_all_day: boolean = true

  public company_contact_ids: any = [] // array id for company contacts

  public attendee_ids: any = [] // users id from the system aka Backend

  public event_params: any = {
    conferenceDataVersion: 0,
    sendUpdates: 'all',
    supportsAttachments: false,
  }

  public get use_default() {
    if (!this.reminders) return true
    if (this.reminders.useDefault === true) return true
    return this.reminders.overrides.length === 0
  }

  public get google_meet() {
    if (!this.conferenceData) {
      return {
        uri: '',
        label: '',
      }
    }
    return this.conferenceData.entryPoints.find(
      (entryPoint: any) => entryPoint.entryPointType === 'video',
    )
  }

  public start: CalendarEventInterface = {
    date: null,
    dateTime: null,
    timeZone: null,
  }

  public end: CalendarEventInterface = {
    date: null,
    dateTime: null,
    timeZone: null,
  }

  public attendees: any = null

  public conferenceData: any = null

  public reminders: any = null

  public switchTimeZone() {
    const start_tz = this.start.timeZone
    const end_tz = this.end.timeZone
    setTimeout(() => {
      this.start.timeZone = end_tz
      this.end.timeZone = start_tz
    }, 200)
  }

  public get useSeparatedTZ() {
    return this.use_separated_tz
  }

  public set useSeparatedTZ(use_separated_tz: boolean) {
    this.use_separated_tz = use_separated_tz
  }

  public get all_day() {
    return (
      ((!this.start.dateTime && !this.end.dateTime) || this.start.dateTime === this.end.dateTime)
      && this.use_all_day
    )
  }

  public set all_day(all_day: boolean) {
    this.use_all_day = all_day
    this.resetDateValues(all_day)
  }

  public resetDateValues(all_day: boolean) {
    if (all_day) {
      this.quickDateChange('dateTime', null)
      this.quickDateChange('timeZone', null)
      this.quickDateChange('date', moment().format('YYYY-MM-DD'))
    } else {
      this.quickDateChange('date', null)
      this.quickDateChange('dateTime', moment().format('YYYY-MM-DDTHH:mm:ss'))
      this.quickDateChange('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
  }

  public quickDateChange(key: string, value: string | null) {
    // @ts-ignore
    this.start[key] = value
    // @ts-ignore
    this.end[key] = value

    return this
  }

  public get apiData() {
    let data = {
      id: this.id,
      calendar_id: this.calendar_id,
      attendeesOmitted: this.attendeesOmitted,
      colorId: this.colorId,
      description: this.description,
      endTimeUnspecified: this.endTimeUnspecified,
      etag: this.etag,
      eventType: this.eventType,
      guestsCanInviteOthers: this.guestsCanInviteOthers,
      guestsCanModify: this.guestsCanModify,
      guestsCanSeeOtherGuests: this.guestsCanSeeOtherGuests,
      hangoutLink: this.hangoutLink,
      htmlLink: this.htmlLink,
      iCalUID: this.iCalUID,
      kind: this.kind,
      location: this.location,
      locked: this.locked,
      privateCopy: this.privateCopy,
      recurrence: this.recurrence,
      recurringEventId: this.recurringEventId,
      sequence: this.sequence,
      status: this.status,
      summary: this.summary,
      transparency: this.transparency,
      updated: this.updated,
      visibility: this.visibility,
      creator: this.creator,
      organizer: this.organizer,
      start: this.start,
      end: this.end,
      attendees: this.attendees,
      conferenceData: this.conferenceData,
      reminders: this.reminders,
      useDefault: this.use_default,
      event_params: this.event_params,
      attendee_ids: this.attendee_ids,
      company_contact_ids: this.company_contact_ids,
    }
    data.event_params.conferenceDataVersion = data.event_params.conferenceDataVersion ? 1 : 0
    return data
  }

  public static onSave(response: any) {
    WebMessage.success('Event saved!')
    return response
  }

  public static onDelete(response: any, meta: any = null) {
    WebMessage.success('Event deleted!')

    return response
  }

  public static onError(error: any) {
    WebMessage.error(error.message)
  }

  public deleteEvent() {
    return this.delete().then((response: any) => {
      CalendarEvent.onDelete(response)
      return response
    })
  }

  public saveEvent() {
    this.name = this.summary
    return this.save()
      .then((response: any) => response)
      .catch((error: any) => error)
  }
}
