
import {
  Vue, Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import Inventory from '@/models/Inventory'
// @ts-ignore
import LiquorTree from 'liquor-tree'

import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import InventoryModule from '@/store/model/InventoryModule'
import { getModule } from 'vuex-module-decorators'

Vue.use(LiquorTree)

@Component({
  components: {
    FormInput,
    SelectPicker,
  },
})
export default class AdunitPicker extends Vue {
  @Ref() readonly treeview: any

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ default: 'tree' })
  public type!: string

  @Prop({ default: 'Parent Ad unit' })
  public label!: string

  @Prop()
  public adserver!: any

  @Prop()
  public publisher_id!: any

  @Prop({ default: null })
  public is_fallback!: any

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: () => [], required: true })
  public value!: string[]

  @Prop({ default: '' })
  public rules!: [Object, String]

  @Prop({ default: false })
  public ignore_tree_load!: boolean

  public adunit_filter: string = ''

  public adunits = []

  public busy = true

  public local_options: any = []

  public ready: boolean = false

  public tree_options = {
    checkbox: true,
    checkOnSelect: false,
    autoCheckChildren: false,
  }

  public adunit_options: any = []

  public selected_parent: any = null

  public inventoryModule: InventoryModule = getModule(InventoryModule)

  @Watch('selected_parent')
  private onParentSelect(val: any) {
    if (val && val.length) {
      this.$emit('input', val)
    }
  }

  @Watch('publisher_id')
  private onPublisherSelect(val: any) {
    if (val && val.length && !this.busy) {
      this.loadBySelection()
    }
  }

  @Watch('adserver')
  private onServerChange(val: any) {
    if (val && val.length && !this.busy) {
      this.loadBySelection()
    }
  }

  public get formLabel() {
    return this.label + (this.rules.includes('required') ? ' *' : '')
  }

  public get local_value(): string[] {
    return this.value
  }

  public set local_value(val: string[]) {
    this.$emit('input', val)
  }

  public updateAdUnits() {
    this.local_value = this.treeview.checked().map((x: any) => x.id)

    /* if (this.model.adunit_ids.length > 0) {
      let base = 100 / this.model.adunit_ids.length
      let distribution = []
      for (let i = 0; i < this.model.adunit_ids.length; i++) {
        distribution.push(base * (i + 1))
      }
      this.adunits_distribution = distribution
    } */
  }

  @Watch('value')
  private onValueUpdate() {
    if (this.adunits.length > 0) {
      this.syncChecked()
    }
  }

  private syncChecked() {
    this.treeview.findAll((n: any) => this.value?.includes(n.id)).check()
  }

  public created() {
    if (!this.ignore_tree_load) {
      Inventory.module.getTree(this.adserver ?? '').then(tree => {
        this.adunits = tree
        this.busy = false

        setTimeout(() => {
          this.syncChecked()
        }, 500)
      })
    }

    if (this.adserver || this.publisher_id) {
      this.loadBySelection()
    }
  }

  public mounted() {
    if (this.type !== 'tree') {
      if (this.value) {
        if (this.value) {
          Inventory.searchOptions({
            value: this.value,
          }).then(response => {
            this.local_options = response
            this.busy = false
            this.ready = true
            this.selected_parent = this.value ?? ''
          })
        } else {
          this.selected_parent = this.value ?? ''
          setTimeout(() => {
            this.busy = false
          }, 500)
          this.searchOptions()
        }
      } else {
        this.busy = false
        this.searchOptions()
      }
    }
  }

  public loadBySelection() {
    Inventory.search({
      adserver: this.adserver,
      publisher_id: this.publisher_id,
      is_fallback: this.is_fallback,
    }).then(response => {
      this.adunit_options = response.data.result.options
    })
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    Inventory.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
      adserver: this.adserver,
      publisher_id: this.publisher_id,
      is_fallback: this.is_fallback,
    }).then(response => {
      this.local_options = response
      this.busy = false
      this.ready = true
    })
  }
}
