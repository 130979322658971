
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import RichEditor from '@/components/RichEditor/RichEditor.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import DemoTargetBuilder from '@/components/DemoTargetBuilder/DemoTargetBuilder.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import FilePicker from '@/components/FilePicker/FilePicker.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import AdunitPicker from '@/components/AdunitPicker/AdunitPicker.vue'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import MediaPlanBuilder from '@/components/MediaPlanBuilder/MediaPlanBuilder.vue'
import TermsPicker from '@/components/TermsPicker/TermsPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'

import { clone as _clone } from 'lodash'
import GeoTargetting from '@/pages/LineItem/components/Targetting/GeoTargetting.vue'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import CustomPicker from '../CustomPicker/CustomPicker.vue'
import CompanyBillingInformation from '../CompanyBillingInformation/CompanyBillingInformation.vue'
import IconAction from '../IconAction/IconAction.vue'
import OpportunityPicker from '../OpportunityPicker/OpportunityPicker.vue'

import AdunitWrapper from '../AdunitSelector/AdunitWrapper.vue'

@Component({
  components: {
    UserPicker,
    FormInput,
    SelectPicker,
    DemoTargetBuilder,
    CompanyPicker,
    FilePicker,
    CheckboxInput,
    AdunitPicker,
    MediaPackagePicker,
    MediaPlanBuilder,
    TermsPicker,
    DatePicker,
    RichEditor,
    CustomPicker,
    CompanyBillingInformation,
    IconAction,
    OpportunityPicker,
    GeoTargetting,
    ModelFileUploader,
    AdunitWrapper,
  },
})
export default class DataFormInput extends Vue {
  @Prop()
  private value: any

  @Prop()
  public field: any

  public automated_files: any = []

  public get localBaseValue() {
    return this.value
  }

  public set localBaseValue(value: any) {
    this.$emit('input', value)
  }

  public get localValue() {
    if (this.field.target.includes('.')) {
      const target = this.field.target.split('.')
      if (target.length === 2) {
        return this.value[target[0]][target[1]]
      }
      if (target.length === 3) {
        return this.value[target[0]][target[1]][target[2]]
      }
      if (target.length === 4) {
        return this.value[target[0]][target[1]][target[2]][target[3]]
      }
      if (target.length === 5) {
        return this.value[target[0]][target[1]][target[2]][target[3]][target[4]]
      }
    }
    return this.value[this.field.target]
  }

  public set localValue(value: any) {
    let v = _clone(this.value)

    if (this.field.target.includes('.')) {
      const target = this.field.target.split('.')

      if (target.length === 2) {
        v[target[0]][target[1]] = value
      } else if (target.length === 3) {
        v[target[0]][target[1]][target[2]] = value
      } else if (target.length === 4) {
        v[target[0]][target[1]][target[2]][target[3]] = value
      } else if (target.length === 5) {
        v[target[0]][target[1]][target[2]][target[3]][target[4]] = value
      }
    } else {
      v[this.field.target] = value
    }
    this.$emit('input', v)
  }
}
