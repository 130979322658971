
import Company from '@/models/Company'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'

@Component({
  components: {},
})
export default class CompanyBillingInformation extends Vue {
  @Prop({ default: null })
  public company_id!: string

  public company: Company = new Company()

  public loading: boolean = true

  @Prop({ default: false })
  public action_acknowledged!: boolean

  @Prop({ default: false })
  public custom_billing_information!: boolean

  public get local_action_acknowledged(): boolean {
    return this.action_acknowledged
  }

  public set local_action_acknowledged(value: boolean) {
    this.$emit('update:action_acknowledged', value)
  }

  public get local_custom_billing_information(): boolean {
    return this.custom_billing_information
  }

  public set local_custom_billing_information(value: boolean) {
    this.$emit('update:custom_billing_information', value)
  }

  @Watch('company_id')
  public async onCompanyChanged() {
    this.getCompany()
  }

  public mounted() {
    this.getCompany()
  }

  public getCompany() {
    if (!this.company_id) {
      return
    }
    this.loading = true
    Company.find(this.company_id).then((response: any) => {
      this.company = response
      if (!this.company.isBillingInformationComplete) {
        this.local_action_acknowledged = false
      }
      this.loading = false
    })
  }

  public editCompany() {
    if (this.company.id) {
      window.open(`${window.location.origin}/app/company/${this.company.id}`)
    }
  }
}
