/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import ReportView from '@/models/ReportView'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'report_view',
})
export default class ReportViewModule extends VuexModule {
  public data: ReportView[] = []

  public options: SelectOption[] = []

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(report_views: ReportView | ReportView[]) {
    let data: Array<ReportView> = []
    if (report_views instanceof ReportView) {
      data.push(report_views)
    } else {
      data = report_views
    }

    let result = this.data

    data.forEach((report_view: ReportView) => {
      let found: boolean = false
      result = result.map((obj: ReportView) => {
        if (obj.id == report_view.id) {
          found = true
          return report_view
        }
        return obj
      })

      if (!found) {
        result.push(report_view)
      }
    })

    this.data = result
  }

  @Mutation
  public delete(report_views: ReportView | ReportView[]) {
    let data: Array<ReportView> = []
    if (report_views instanceof ReportView) {
      data.push(report_views)
    } else {
      data = report_views
    }

    let result = this.data

    data.forEach((report_view: ReportView) => {
      result = result.filter((obj: ReportView) => obj.id != report_view.id)
    })

    this.data = result
  }

  @Action
  public async find(id: string): Promise<ReportView | null> {
    return new Promise(resolve => {
      const o = this.data.find(report_view => report_view.id === id)

      if (o instanceof ReportView) {
        resolve(o)
      } else {
        return this.api
          .get(`report_view/${id}`)
          .then(response => {
            // Parse & cache data
            const data = ReportView.toObject(response.data.result.report_view)

            if (data instanceof ReportView) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('report_views/paginate', options)
      .then(response => {
        // Parse & cache data

        const data = ReportView.toObjectList(response.data.result.report_views)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('report_views/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
