
import { Component, Prop, Vue } from 'vue-property-decorator'
import AdunitSelector from './AdunitSelector.vue'

@Component({
  components: {
    AdunitSelector,
  },
})
export default class AdunitWrapper extends Vue {
  @Prop()
  public rules!: string

  @Prop({ default: false })
  public withErrorMsg!: boolean

  @Prop({ default: 'Ad Units' })
  public label!: boolean

  @Prop({
    default: () => ({
      wrapper: null,
    }),
  })
  public componentClass!: any

  @Prop({ default: 'ad_unit_wrapper' })
  public local_id!: boolean

  @Prop()
  public included!: any

  @Prop()
  public excluded!: any

  @Prop()
  public adserver!: string

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public collapse!: boolean

  @Prop({ default: true })
  public collapsable!: boolean

  public error_config: any = {
    error: false,
    message: null,
  }

  public get local_included() {
    return this.included ?? []
  }

  public set local_included(value: any) {
    this.$emit('update:included', value)
  }

  public get local_excluded() {
    return this.excluded ?? []
  }

  public set local_excluded(value: any) {
    this.$emit('update:excluded', value)
  }

  public get local_colapse() {
    return this.collapse
  }

  public set local_colapse(value: any) {
    this.$emit('update:collapse', value)
  }

  public get local_rules() {
    return this.rules
  }

  public errorHandler(errors: any) {
    this.error_config.message = errors
    this.error_config.error = errors
  }
}
