
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import CompanyContact from '@/models/CompanyContact'
import ListBlock from './ListBlock.vue'
import IconAction from '../IconAction/IconAction.vue'

@Component({
  components: {
    ListBlock,
    IconAction,
  },
})
export default class ContactAgenda extends ViewModel {
  @Prop()
  public value!: any

  @Prop({ default: false })
  public disabled!: any

  public local_contacts: any = []

  public selected: any = null

  public selectContact(contact: any) {
    this.selected = contact
  }

  public mounted() {
    if (this.value) {
      this.loadContactsById()
    }
  }

  public loadContactsById() {
    CompanyContact.getContacts(this.value).then((res: any) => {
      this.local_contacts = res.data.result
    })
  }

  public editContact(contact: any) {
    this.$emit('edit', contact)
    setTimeout(() => {
      this.selectContact(null)
    }, 10)
  }

  public short_name(name: any) {
    const limit = 50
    if (!name) return ''
    return name.length > limit ? `${name.substring(0, limit)}...` : name
  }
}
