
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import CalendarModule from '@/models/Calendar'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import DataFormInput from '@/components/DataForm/DataFormInput.vue'
import moment from 'moment'
import CalendarEvent from '@/models/CalendarEvent'
import WebMessage from '@/models/WebMessage'
import CompanyContactPicker from '@/components/CompanyContactPicker/CompanyContactPicker.vue'
import time_zone_list from './components/timezone-list'
import CalendarPicker from './components/CalendarPicker.vue'
import ColorPicker from './components/ColorPicker.vue'

@Component({
  components: {
    FullCalendar,
    CalendarPicker,
    IconAction,
    FormInput,
    DatePicker,
    CheckboxInput,
    SelectPicker,
    UserPicker,
    DataFormInput,
    ColorPicker,
    CompanyContactPicker,
  },
})
export default class CalendarHome extends ViewModel {
  public DEBUG: boolean = false // EXTRA FUNCTIONS TO DEBUG

  @Ref() public readonly full_calendar!: any

  public calendar: CalendarModule = new CalendarModule()

  public selected_date: any = null

  public loading: boolean = false

  public mannaging: boolean = false

  public calendar_event: CalendarEvent = new CalendarEvent()

  @Watch('mannaging')
  public onMannagingChange(val: any) {
    if (!val) {
      this.calendar_event = null
    }
  }

  @Watch('selected_date')
  public onEventObjChange(val: any) {
    if (val) {
      this.calendar_event = new CalendarEvent()
      this.mannaging = true
      setTimeout(() => {
        this.calendar_event.start.date = val
        this.calendar_event.end.date = val
        this.calendar_event.start.timeZone = null
        this.calendar_event.end.timeZone = null
      }, 100)
    }
  }

  public temp_timezone_settings: any = {
    useSeparatedTZ: false,
    start: {
      date: '',
      dateTime: '',
      timeZone: '',
    },
    end: {
      date: '',
      dateTime: '',
      timeZone: '',
    },
  }

  public selecting_timezone: boolean = false

  public timezones: any = []

  public separated_timezones: boolean = false

  public is_syncronizing: boolean = false

  public notifications: any = {
    types: [
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Notification',
        value: 'popup',
      },
    ],
    time: [
      {
        text: 'Minutes',
        value: 'minutes',
      },
      {
        text: 'Hours',
        value: 'hours',
      },
      {
        text: 'Days',
        value: 'days',
      },
      {
        text: 'Weeks',
        value: 'weeks',
      },
    ],
  }

  public get isEditing() {
    if (!this.calendar_event) return false
    return this.calendar_event.id && this.calendar_event.id.length
  }

  public mounted() {
    this.timezones = time_zone_list
    this.refresh()
    setTimeout(() => {
      if (localStorage.getItem('calendar_sync')) {
        this.is_syncronizing = true
      }
    }, 200)

    Vue.prototype.$echo.private(`user.${this.user!.id}`).listen('GoogleCalendarSync', (e: any) => {
      window.location.reload()
    })
  }

  public changeView(e) {
    if (e == 'prev') {
      this.full_calendar.getApi().prev()
    } else {
      this.full_calendar.getApi().next()
    }

    setTimeout(() => {
      this.refresh()
    }, 300)
  }

  public createEvent() {
    this.calendar_event = new CalendarEvent()
    this.mannaging = true
  }

  public calendar_settings: any = {
    isLoading: () => this.loading,
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, rrulePlugin],
    initialDate: this.selected_date,
    initialView: 'dayGridMonth',
    nowIndicator: false,
    events: this.fullCalendarEventFormat,
    headerToolbar: {
      start: 'prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    bootstrapFontAwesome: {
      close: ' la la-times',
      prev: ' la la-angle-left',
      next: ' la la-angle-right',
      prevYear: ' la la-angle-double-left',
      nextYear: ' la la-angle-double-right',
    },
    buttonText: {
      today: 'Today',
      dayGridMonth: 'Month',
      timeGridWeek: 'Week',
      timeGridDay: 'Day',
      listMonth: 'List',
    },
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: true,
    allDaySlot: false,
    select: this.handleDateSelect,
    eventClick: this.handleEventClick,
    eventsSet: this.handleEvents,
    eventDrop: this.handleEventDrop,
    customButtons: {
      prev: {
        text: 'Prev',
        click: () => this.changeView('prev'),
      },
      next: {
        text: 'Next',
        click: () => this.changeView('next'),
      },
    },

  }

  public get calendar_options() {
    return this.calendar_settings
  }

  public connectOAuth(provider: string) {
    let grant = ''
    if (this.user.isRoot) {
      grant = '?with_grant=true'
    }

    if (grant.length) grant = grant.concat('&from=calendar')
    else grant = '?from=calendar'
    window.location.href = `${process.env.VUE_APP_BASE_API_URL}oauth/${provider}/login${grant}`
  }

  public resetTempTimezone() {
    setTimeout(() => {
      this.temp_timezone_settings = {
        useSeparatedTZ: false,
        start: {
          date: '',
          dateTime: '',
          timeZone: '',
        },
        end: {
          date: '',
          dateTime: '',
          timeZone: '',
        },
      }
    }, 100)
  }

  /**
   * @description Switch the timezone between start and end
   */
  public switchTimeZone() {
    const start_tz = this.temp_timezone_settings.start.timeZone
    const end_tz = this.temp_timezone_settings.end.timeZone
    setTimeout(() => {
      this.temp_timezone_settings.start.timeZone = end_tz
      this.temp_timezone_settings.end.timeZone = start_tz
    }, 200)
  }

  public cancelTimeZone() {
    this.selecting_timezone = false
    this.resetTempTimezone()
  }

  /**
   * @description Apply the timezone settings to the event
   *
   * Its saved in a temp variable to avoid the user to change the timezone.
   *
   * When applied the temp is saved in the Model and the temp is reseted
   */

  public applyTimeZone() {
    Vue.set(this.calendar_event.start, 'timeZone', this.temp_timezone_settings.start.timeZone)
    if (this.temp_timezone_settings.useSeparatedTZ) {
      Vue.set(this.calendar_event.end, 'timeZone', this.temp_timezone_settings.end.timeZone)
    } else {
      Vue.set(this.calendar_event.end, 'timeZone', this.temp_timezone_settings.start.timeZone)
    }
    Vue.set(this.calendar_event, 'useSeparatedTZ', this.temp_timezone_settings.useSeparatedTZ)
    this.selecting_timezone = false
    this.resetTempTimezone()
  }

  /**
   * @description Check if the event has a timezone setted and save it in a temp variable
   */
  public checkTimeZoneIfSetted() {
    if (this.calendar_event.start.timeZone && this.calendar_event.start.timeZone.length) {
      this.temp_timezone_settings.start.timeZone = this.calendar_event.start.timeZone
    }
    if (this.calendar_event.end.timeZone && this.calendar_event.end.timeZone.length) {
      this.temp_timezone_settings.end.timeZone = this.calendar_event.end.timeZone
    }

    this.temp_timezone_settings.useSeparatedTZ = this.calendar_event.useSeparatedTZ
  }

  public handleEventDrop(e: any) {
    // console.log('eventDrop', e.event.start)
    const { start, end } = e.event
    // update event by id
    const event = this.calendar.events.find((event: CalendarEvent) => event.id == e.event.id)
    this.calendar_event = Object.assign(new CalendarEvent(), event)

    // console.log('handleEventDrop', e)

    // console.log({ start, end })

    // TODO continue here  james
  }

  public handleDateSelect(selectInfo: any) { }

  public handleEventClick(e: any) {
    const event = this.calendar.events.find((event: CalendarEvent) => event.id == e.event.id)
    this.calendar_event = Object.assign(new CalendarEvent(), event)
    this.mannaging = true
  }

  public handleEvents(events: any) {
    // this.currentEvents = events;
  }

  public get fullCalendarEventFormat() {
    return this.calendar.events.map(event => {
      let obj = {
        id: event.id,
        title: `${event.summary}`,
        start: event.start.dateTime,
        end: event.end.dateTime,
        display: 'block',
        backgroundColor: event?.colors?.background || null,
        borderColor: event?.colors?.foreground || null,
      }
      if (event.sequence) {
        obj.startRecur = event.start.date || event.start.dateTime
        obj.endRecur = event.end.date || event.end.dateTime
      }
      return obj
    })
  }

  public async refresh() {
    this.loading = true
    let types: any = {
      dayGridMonth: 'month',
      timeGridWeek: 'week',
      timeGridDay: 'day',
      listMonth: 'list',
      today: 'today',
    }

    let event_settings = {
      view_type: types[this.full_calendar.getApi().getCurrentData().currentViewType] || null,
      start_date: this.full_calendar.getApi().getDate(),
    }

    CalendarModule.getCalendar(event_settings).then(calendar => {
      this.calendar = calendar

      this.calendar_settings.events = this.fullCalendarEventFormat

      this.full_calendar.getApi().setOption('events', this.calendar_settings.events)

      if (this.is_syncronizing || calendar) {
        this.setSyncState(false)
      }

      this.loading = false

      return true
    }).catch(() => {
      this.loading = false
    })
  }

  public syncEvents() {
    WebMessage.info('Calendar Events Sync, this might take a while')
    this.loading = true
    CalendarModule.syncEvents().then(calendar => {
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  }

  public syncCalendar(by: any = null) {
    this.setSyncState()
    WebMessage.info('Calendar Sync, this might take a while')
    this.loading = true
    CalendarModule.sync().then(calendar => {
      this.calendar_settings.events = this.fullCalendarEventFormat
      this.full_calendar.getApi().setOption('events', this.calendar_settings.events)
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  }

  public setSyncState(to_sync: any = true) {
    this.is_syncronizing = to_sync
    if (to_sync) localStorage.setItem('calendar_sync', 'synchronizing')
    else localStorage.removeItem('calendar_sync')
  }

  public cancelEdition() {
    this.mannaging = false
    this.calendar_event = null
    this.resetTempTimezone()
  }

  public deleteEvent() {
    this.mannaging = false
    this.loading = true
    this.calendar_event.deleteEvent().then(() => {
      this.refresh()
    }).catch(() => {
      this.loading = false
      this.mannaging = false
    })
  }

  public saveEvent() {
    this.loading = true
    this.mannaging = false
    this.calendar_event.saveEvent().then(() => {
      this.refresh()
    }).catch(() => {
      this.loading = false
      this.mannaging = false
    })
  }
}
