
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Opportunity from '@/models/Opportunity'
import SelectOption from '@/models/interface/SelectOption'
import SelectPicker from '../SelectPicker/SelectPicker.vue'

@Component({
  components: {
    SelectPicker,
  },
})
export default class OpportunityPicker extends ViewModel {
  @Ref() public picker!: any

  @Prop()
  public value!: any

  @Prop()
  public company!: any

  public ready: boolean = false

  public busy: boolean = true

  public loading: boolean = false

  public options: any = []

  public uuid: string = ''

  public label_name: string = 'N/A'

  public selected_opt: SelectOption | null = null

  public update: boolean = true

  @Watch('value')
  public onChangeProp() {
    if (this.update) {
      let has_options = false
      if (Array.isArray(this.value)) {
        has_options = true
        this.value.forEach((v: any) => {
          if (!this.options.some((o: any) => o.value === v)) {
            has_options = false
          }
        })
      }
      if (
        (Array.isArray(this.value) && this.value.length > 0 && !has_options)
        || typeof this.value === 'string'
      ) {
        Opportunity.searchOptions({
          value: Array.isArray(this.value) ? this.value : [this.value],
          query: this.companyId(),
        }).then(response => {
          this.options = response
          this.busy = false
          this.local_value = this.value
          this.ready = true
        })
      } else {
        this.local_value = this.value
      }
    }
    this.update = true
    this.updateLabel()
  }

  @Watch('local_value')
  public onChangeValue() {
    this.update = false
    this.$emit('input', this.local_value)
  }

  public get local_value() {
    return this.value || ''
  }

  public set local_value(value: any) {
    this.$emit('input', value)
  }

  public updateLabel() {
    if (!this.value) {
      this.label_name = 'N/A'
    } else if (!Array.isArray(this.value)) {
      Opportunity.searchOptions({
        value: this.value,
        query: this.companyId(),
      }).then(response => {
        this.label_name = response[0]?.name
        if (response.length) {
          this.selected_opt = response[0]
        }
        this.ready = true
      })
    }
  }

  public created() {
    if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
      this.ready = true
    } else {
      this.busy = true
      Opportunity.searchOptions({
        value: this.value,
        query: this.companyId(),
      }).then(response => {
        this.options = response
        this.busy = false
        this.local_value = this.value
        this.label_name = response[0]?.name
        this.updateLabel()
        this.ready = true
      })
    }
  }

  public mounted() {
    this.uuid = this.randomUUID()
    this.local_value = this.value
  }

  public companyId() {
    if (this.company) return `${this.company.type}_id:${this.company.id}`
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()

    this.setUniqueOptions()
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    Opportunity.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
      query: this.companyId(),
    }).then(response => {
      // if (this.user.id == '8e3e41ea-927d-4a19-be60-39c856c5b212') {
      //   console.log('original', this.options, response)
      // }
      this.options = response
      // unique options by value
      this.setUniqueOptions()
      // if (this.user.id == '8e3e41ea-927d-4a19-be60-39c856c5b212') {
      //   console.log('new', this.options, response)
      // }

      this.busy = false
      this.ready = true
    })
  }

  public setUniqueOptions() {
    let ids: string[] = []
    let new_options: any[] = []
    this.options.forEach((o: any) => {
      if (!ids.includes(o.value)) {
        ids.push(o.value)
        new_options.push(o)
      }
    })

    Vue.set(this, 'options', [...new_options])
    // Vue.set(this, 'options', [...new Set(this.options)])
  }
}
