
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'

@Component
export default class ColorPicker extends ViewModel {
  @Prop({ default: '' })
  public value!: string

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: 'text' })
  public label_format!: string

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: true })
  public vertical!: boolean

  @Prop({ default: 0 })
  public debounce!: number

  @Prop({ default: false })
  public lazy!: boolean

  @Prop({ default: true })
  public description!: boolean

  public local_value: any = null

  // google calendar label colors
  public label_colors: any = [
    {
      name: 'Lavender',
      value: '#a4bdfc',
    },
    {
      name: 'Sage',
      value: '#7ae7bf',
    },
    {
      name: 'Grape',
      value: '#dbadff',
    },
    {
      name: 'Flamingo',
      value: '#ff887c',
    },
    {
      name: 'Banana',
      value: '#fbd75b',
    },
    {
      name: 'Tangerine',
      value: '#ffb878',
    },
    {
      name: 'Peacock',
      value: '#46d6db',
    },
    {
      name: 'Graphite',
      value: '#e1e1e1',
    },
    {
      name: 'Blueberry',
      value: '#5484ed',
    },
    {
      name: 'Basil',
      value: '#51b749',
    },
    {
      name: 'Tomato',
      value: '#dc2127',
    },
    {
      name: 'Mandarin',
      value: '#ff8a00',
    },
  ]

  @Watch('local_value')
  public onLocalValueChange(val: any) {
    if (val) this.$emit('input', val)
  }

  public created() {
    if (this.value) this.local_value = this.value
    else this.local_value = this.label_colors[0]
  }
}
