
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import countires_list from '@/data/country_options'
import GeoTargettingModule from '@/models/GeoTargettingModule'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import SelectOption from '@/models/interface/SelectOption'
import CloseTag from '@/components/CloseTag/CloseTag.vue'
import { GeoTargetingNode } from '@/models/interface/TargetingNode'

@Component({
  components: {
    FormInput,
    IconAction,
    SearchInput,
    CloseTag,
  },
})
export default class GeoTargetting extends ViewModel {
  @Prop()
  public value!: any

  @Prop()
  public fixed_country!: any

  @Prop({ default: true })
  public pre_select!: boolean

  @Prop({ default: false })
  public new_item!: boolean

  public selected_nodes: Array<any> = []

  public filter: any = null

  public country_list: any = []

  public dma_list: any = []

  public states: any = null

  public loaded_cities: any = null

  public geoTargetting: GeoTargettingModule = new GeoTargettingModule()

  public searched: any = []

  public searching: boolean = false

  public loading: boolean = true

  public first_add: boolean = true

  public resetting_filters: boolean = false

  public query_settings = {
    geo_location: [
      {
        name: 'dma',
        key: 'type',
        type: 'dma',
        description: 'Include only the specified DMA',
      },
      {
        name: 'country',
        key: 'type',
        type: 'country',
        description: 'Include only the specified Country',
      },
      {
        name: 'state',
        key: 'type',
        type: 'state',
        description: 'Include only the specified State',
      },
      {
        name: 'city',
        key: 'type',
        type: 'city',
        description: 'Include only the specified City',
      },
    ],
  }

  public tmp_zipcodes = ''

  public all_loaded: boolean = false

  public include_zipcodes = true

  public props_names: any = {
    country: 'countries',
    state: 'states',
    city: 'cities',
    dma: 'dmas',
    zip: 'zips',
  }

  @Watch('filter')
  public onFilterChange(val: any) {
    if (val && val.length) {
      this.geoSearch()
      return
    }

    if (val === null || !val.length) {
      // console.log('watching filter', val)
      this.states = {}
      this.loaded_cities = {}
      this.searched = [];
      (async () => {
        this.resetting_filters = true
        if (this.all_loaded) {
          await this.newloadAllData(true)
        }
        this.resetting_filters = false
      })()
    }
  }

  public showZipcodeModal() {
    this.tmp_zipcodes = ''
    this.$bvModal.show('zipcode-modal')
  }

  public addZipcodes() {
    let zipcodes: string[] = this.tmp_zipcodes
      // Split line break or comma
      .split(/[\r\n,]+/g)
      // filter empty records
      .filter(z => z !== '')

    // remove duplicated lines
    zipcodes = [...new Set([...zipcodes])]

    if (zipcodes.length > 0) {
      zipcodes.forEach((zipcode: any) => {
        let index = this.selected_nodes.findIndex(
          (node: any) => node.value === zipcode.trim() && node.type === 'zip',
        )

        if (index > -1) {
          this.selected_nodes[index].action = this.include_zipcodes ? 'include' : 'exclude'
          this.emitByDemand('zip', this.include_zipcodes ? 'include' : 'exclude', index)
        } else if (zipcode.trim().length && !isNaN(zipcode)) {
          this.selected_nodes.push({
            name: zipcode.trim(),
            value: zipcode.trim(),
            type: 'zip',
            action: this.include_zipcodes ? 'include' : 'exclude',
          })
          this.emitByDemand('zip', this.include_zipcodes ? 'include' : 'exclude')
        }
      })
    }
  }

  public validateStates(country_code: any) {
    if (!this.states) return false
    if (!this.states[country_code]) return false
    if (this.states[country_code] === null) return false
    return this.states[country_code] !== 'loading'
  }

  public validateCities(country_code: any, state_code: any) {
    let keyName = `${country_code}-${state_code}`
    if (!this.loaded_cities) return false
    if (!this.loaded_cities[keyName]) return false
    if (this.loaded_cities[keyName] === null) return false
    return this.loaded_cities[keyName] !== 'loading'
  }

  public get list_of_countires() {
    let first_row = this.country_list.filter(
      country => country.country_code === 'US' && country.value === 'US',
    )
    return [
      ...first_row,
      ...this.country_list
        .filter(country => country.country_code !== 'US' && country.value !== 'US')
        .sort((a, b) => a.name?.localeCompare(b.name))
        .sort((a, b) => a.type?.localeCompare(b.type)),
      ...this.dma_list,
    ]
  }

  public get list_length() {
    return this.list_of_countires.length
  }

  // Search
  public async geoSearch() {
    this.searching = true

    let query: any = {
      value: this.filter,
    }

    if (this.filter.includes('country:') && this.country_list.length) {
      let country = this.filter.split('country:')[1]

      this.searched = this.country_list.filter(item =>
        item.name.toLowerCase().includes(country.toLowerCase()))

      this.searching = false
      return
    }
    if (this.filter.includes('dma:') && this.dma_list.length) {
      let dma = this.filter.split('dma:')[1]

      this.searched = this.dma_list.filter(item => {
        if (isNaN(dma)) {
          return item.name.toLowerCase().includes(dma.toLowerCase())
        }
        return item.value.includes(dma)
      })

      this.searching = false

      return
    }
    this.country_list = []
    let search = this.filter.split(':')

    if (search.length > 1) {
      query = {
        value: search[1],
        type: search[0],
        exact: true,
      }
    } else {
      query = {
        search: search[0],
        // type: search[0],
      }
    }

    this.geoTargetting.searchOptions(query).then(response => {
      this.searched = response.sort((a, b) => a.name?.localeCompare(b.name))
      setTimeout(() => {
        this.searching = false
      }, 500)
    })
  }

  public load_state_cities(country_code: any, state_code: any, inject: boolean = false) {
    if (inject) {
      this.states = {}
      this.states[country_code] = []
    }
    this.loaded_cities = null
    if (!this.loaded_cities) {
      this.loaded_cities = {}
    }

    let keyName = `${country_code}-${state_code}`

    let load = () => {
      if (!this.loaded_cities[keyName]) {
        Vue.set(this.loaded_cities, keyName, null)
      }
      Vue.set(this.loaded_cities, keyName, 'loading')

      this.geoTargetting
        .geoSearchAll({ state_code, country_code, type: 'city' })
        .then(response => {
          let data = response.data.result.options
          setTimeout(() => {
            Vue.set(
              this.loaded_cities,
              keyName,
              data.length ? data.sort((a, b) => a.name.localeCompare(b.name)) : null,
            )
          }, 500)

          setTimeout(() => {
            this.$root.$emit('bv::toggle::collapse', `cities-${keyName}`)
          }, 1000)
        })
    }

    if (Object.keys(this.loaded_cities).length > 2) {
      Vue.set(this, 'loaded_cities', {})
    }

    if (!this.loaded_cities[keyName]) {
      load()
    }
  }

  public load_states(node: any) {
    this.$root.$emit('bv::toggle::collapse', `state-${node.full_name}`)

    let country_code = node.value

    if (!this.states) {
      this.states = {}
    }

    let load = () => {
      if (!this.states[country_code]) {
        Vue.set(this.states, country_code, null)
      }
      Vue.set(this.states, country_code, 'loading')

      this.geoTargetting.geoSearchAll({ country_code, type: 'state' }).then(response => {
        let data = response.data.result.options
        setTimeout(() => {
          Vue.set(
            this.states,
            country_code,
            data.length ? data.sort((a, b) => a.name.localeCompare(b.name)) : null,
          )
        }, 500)
      })
    }

    if (Object.keys(this.states).length > 2) {
      Vue.set(this, 'states', {})
    }

    if (!this.states[country_code]) {
      load()
    }
  }

  public async preSelectCountry(options: any) {
    await new Promise((res, rej) => {
      let default_selection = options.find(
        (c: SelectOption) => c.value === 'US' && c.type === 'country',
      )

      if (
        this.pre_select
        && !this.value.include_countries.length
        && !this.selected_nodes.length
        && !this.$route.params
        && !this.$route?.params?.id
      ) {
        this.selected_nodes = [
          {
            ...default_selection,
            value: default_selection?.value || [],
            action: 'include',
          },
        ]
      }

      // if (!this.selected_nodes.length && !this.$route.params.id) {
      //   this.selected_nodes = [
      //     {
      //       ...default_selection,
      //       value: default_selection?.value || [],
      //       action: 'include',
      //     },
      //   ]
      // }
      setTimeout(() => {
        res(true)
      }, 1000)
    })
  }

  /**
   * loading and injecting data order
   */

  public async loadCountry(args: any = null) {
    let params: any = { type: 'country' }

    if (args) params = { ...params, ...args }

    const response = await this.geoTargetting.geoSearchAll(params)

    const data = response.data.result.options

    this.country_list = data

    if (!this.$route?.params?.id || (!this.$route?.params?.id.length && this.pre_select)) {
      await this.preSelectCountry(response.data.result.options)
    }

    return data
  }

  public async loadDma(args: any = null) {
    let params: any = { type: 'dma' }

    if (args) params = { ...params, ...args }

    const response = await this.geoTargetting.geoSearchAll(params)

    const data = response.data.result.options

    this.dma_list = data.sort((a: any, b: any) => a.name?.localeCompare(b.name))

    return data
  }

  public async loadAllSelectedGeoLocations() {
    await this.newLoadSelectedCountries()

    await this.newLoadSelectedStates()

    await this.newLoadSelectedCities()

    await this.newLoadSelectedDmas()

    await this.newLoadZipcodes()

    return true
  }

  public async newloadAllData(ignore_select: boolean = false) {
    try {
      /* Load countries */
      if (this.fixed_country && !this.value.include_countries.length) {
        await this.loadCountry({ value: this.fixed_country })
      } else {
        await this.loadCountry()
      }

      /* Load dmas */
      await this.loadDma()

      if (!ignore_select) {
        /* Load selected Geo locations */
        await this.loadAllSelectedGeoLocations()
      }

      if (this.all_loaded) this.confirmUniqueValues()

      return true
    } catch (error) {
      return error
    }
  }

  public async newLoadSelectedStates() {
    let search_for = [this.value.include_states, this.value.exclude_states].flat()
    if (search_for && search_for.length) {
      let response = await this.geoTargetting.geoSearchAll({
        value: search_for,
        type: 'state',
      })
      let promises = await response.data.result.options.map(async (state: any) => {
        let action = this.value.include_states.includes(state.value) ? 'include' : 'exclude'
        return {
          ...state,
          action,
        }
      })
      let injectable = await Promise.all(promises)
      this.selected_nodes = this.selected_nodes.concat(injectable)
    } else {
      return Promise.resolve(true)
    }
  }

  public async newLoadSelectedCountries() {
    let search_for = [this.value.include_countries, this.value.exclude_countries].flat()

    if (search_for && search_for.length) {
      let promises = search_for.map(async d => {
        let country = this.country_list.find((cntry: any) => cntry.value === d)
        let action = this.value.include_countries.includes(d) ? 'include' : 'exclude'
        return {
          ...country,
          action,
        }
      })
      let injectable = await Promise.all(promises)

      this.selected_nodes = this.selected_nodes.concat(injectable)
    } else {
      return Promise.resolve(true)
    }
  }

  public async newLoadSelectedCities() {
    let search_for = [this.value.include_cities, this.value.exclude_cities].flat()
    if (search_for && search_for.length) {
      let response = await this.geoTargetting.geoSearchAll({
        value: search_for,
        type: 'city',
      })
      let promises = await response.data.result.options.map(async (city: any) => {
        let action = this.value.include_cities.includes(city.value) ? 'include' : 'exclude'
        return {
          ...city,
          action,
        }
      })
      let injectable = await Promise.all(promises)
      this.selected_nodes = this.selected_nodes.concat(injectable)
    } else {
      return Promise.resolve(true)
    }
  }

  public async newLoadSelectedDmas() {
    let search_for = [this.value.include_dmas, this.value.exclude_dmas].flat()
    if (search_for && search_for.length) {
      let response = await this.geoTargetting.geoSearchAll({
        value: search_for,
        type: 'dma',
      })
      let promises = await response.data.result.options.map(async (city: any) => {
        let action = this.value.include_dmas.includes(Number(city.value)) ? 'include' : 'exclude'
        return {
          ...city,
          action,
        }
      })
      let injectable = await Promise.all(promises)
      this.selected_nodes = this.selected_nodes.concat(injectable)
    } else {
      return Promise.resolve(true)
    }
  }

  public async newLoadZipcodes() {
    let zipcodes = [this.value.include_zips, this.value.exclude_zips].flat()

    if (zipcodes && zipcodes.length) {
      let promises = zipcodes.map(async d => {
        let action = this.value.include_zips.includes(d) ? 'include' : 'exclude'
        return {
          name: d.trim(),
          value: d.trim(),
          type: 'zip',
          action,
        }
      })
      let injectable = await Promise.all(promises)

      this.selected_nodes = this.selected_nodes.concat(injectable)
    } else {
      return Promise.resolve(true)
    }
  }

  public mounted() {
    if (!this.all_loaded) {
      this.newloadAllData()
        .then(res => {
          this.all_loaded = true
          this.loading = false
          this.$emit('all-loaded', true)
        })
        .catch(() => {})
    }
  }

  public isSelected(node: any) {
    return this.selected_nodes.some(sn => sn.value === node.value)
  }

  public isIncluded(node: any) {
    return this.selected_nodes.some(sn => sn.id === node.id && sn.action === 'include')
  }

  public isExcluded(node: any) {
    return this.selected_nodes.some(sn => sn.id === node.id && sn.action === 'exclude')
  }

  public get cantEdit() {
    return this.$attrs.edit !== 'undefined' && !this.$attrs.edit
  }

  public select(node: any, include: boolean = true) {
    if (this.first_add && this.new_item) {
      this.first_add = false

      this.selected_nodes.length = 0
    }

    let index = this.selected_nodes.findIndex(
      (s: any) => s.value === node.value && s.type === node.type,
    )
    let _action = include ? 'include' : 'exclude'
    let obj = {
      ...node,
      action: _action,
      value: node.value,
    }

    if (obj.type === 'city') {
      obj.value = node.value
    }
    if (obj.type === 'state') {
      obj.value = node.value
    }

    if (index > -1) {
      Vue.set(this.selected_nodes[index], 'action', _action)
      this.updatedModel(this.selected_nodes[index])
      this.emitByDemand(node.type, _action, index)
    } else {
      this.selected_nodes.push(obj)
      this.emitByDemand(node.type, _action)
      this.updatedModel(obj)
    }

    // this.emit()
  }

  public remove(index: number) {
    let node = JSON.parse(JSON.stringify(this.selected_nodes[index]))

    this.updatedModel({ ...node, action: 'remove' })

    this.selected_nodes.splice(index, 1)

    setTimeout(() => {
      this.$emit('input', {
        ...this.value,
        [this.atThisKey(node.action, node.type)]: this.selected_nodes
          .filter(n => n.type === node.type && n.action === node.action && n.value !== node.value)
          .map(s => s.value),
      })
    }, 200)
  }

  public removeAll() {
    this.updatedModel(null, true)

    this.selected_nodes = []
    this.$emit('input', {
      ...this.value,
      include_dmas: [],
      exclude_dmas: [],
      include_countries: [],
      exclude_countries: [],
      include_states: [],
      exclude_states: [],
      include_cities: [],
      exclude_cities: [],
      include_zips: [],
      exclude_zips: [],
    })
  }

  public atThisKey(action: string, type: string): any {
    return `${action}_${this.props_names[type]}`
  }

  public emitByDemand(type: string, action: string, index_switch: any = null) {
    if (index_switch !== null) {
      let node = this.selected_nodes[index_switch]

      let reverse_action = node.action === 'exclude' ? 'include' : 'exclude'

      let the_old_position_new_value = this.value[this.atThisKey(reverse_action, type)].filter(
        ov => ov !== node.value,
      )

      this.$emit('input', {
        ...this.value,
        [this.atThisKey(reverse_action, type)]: [...new Set(the_old_position_new_value)],
      })

      setTimeout(() => {
        const copy = JSON.parse(JSON.stringify(this.value))
        copy[this.atThisKey(action, type)] = [
          ...new Set([...copy[this.atThisKey(action, type)], node.value]),
        ]
        this.$emit('input', copy)
      }, 200)
    } else {
      let selected_node: any = this.selected_nodes.filter(
        sn => sn.action === action && sn.type === type,
      )

      this.$emit('input', {
        ...this.value,
        [this.atThisKey(action, type)]: [...new Set(selected_node.map(s => s.value))],
      })
    }
  }

  public confirmUniqueValues() {
    let keys = Object.keys(this.value)
    let uniqueValue: any = {}
    keys.forEach(k => {
      if ((k.includes('include_') || k.includes('exclude_')) && this.value[k] instanceof Array) {
        uniqueValue[k] = [...new Set(this.value[k])]
      }
    })

    const cloned = Object.assign(JSON.parse(JSON.stringify(this.value)), uniqueValue)

    this.$emit('input', cloned)
  }

  public removeSelectionById(id: string) {
    const index = this.selected_nodes.findIndex((s: any) => s.id === id)
    if (index > -1) {
      this.remove(index)
      return true
    }
    return false
  }

  public updatedModel(node: GeoTargetingNode | null, sync = false) {
    if (!node) {
      this.$emit('updated-model', { sync })
      return
    }
    setTimeout(() => {
      this.$emit('updated-model', Object.assign(new GeoTargetingNode(), node))
    }, 100)
  }
}
