
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import { BCalendar, BButtonGroup } from 'bootstrap-vue'

@Component({
  components: {
    BCalendar,
    BButtonGroup,
  },
})
export default class CalendarPicker extends Vue {
  @Ref() public simpleCalendar!: any

  @Prop()
  public value: any

  public context: any = null

  public simple_date_string = ''

  @Watch('simple_date_string')
  public onSimpleDateStringChange(val: string) {
    if (val && val.length) {
      this.$emit('input', val)
    }
  }

  public onContext(ctx: any) {
    this.context = ctx
    this.$emit('fullDateChange', ctx)
  }

  public created() {
    this.simple_date_string = this.value
  }

  public navigateTo(func: string) {
    const actions: any = {
      nextYear: () => this.simpleCalendar.gotoNextYear(),
      prevYear: () => this.simpleCalendar.gotoPrevYear(),
      nextMonth: () => this.simpleCalendar.gotoNextMonth(),
      prevMonth: () => this.simpleCalendar.gotoPrevMonth(),
      today: () => this.simpleCalendar.gotoCurrentMonth(),
    }

    actions[func]()
  }
}
