var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.ready)?_c('ValidationProvider',{ref:"validation",attrs:{"vid":_vm.id,"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_vm._v(" "+_vm._s(errors && errors.length ? _vm.emitErrors(errors[0]) : '')+" "+_vm._s(valid ? _vm.emitErrors(!valid) : '')+" "),_c('input',{attrs:{"type":"text","hidden":""},domProps:{"value":_vm.someValues ? 'selected' : null}}),_c('div',{staticClass:"w-100",class:{
      'border-danger': errors && errors.length,
      'targetting-container selector': !_vm.loading,
    }},[_c('b-row',{class:{
        'loading-container': _vm.loading,
      },attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"h-100",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"card border-0"},[_c('div',{staticClass:"card-header p-1 d-flex align-items-center w-100",staticStyle:{"height":"40px"}},[_c('b-form-input',{staticClass:"input",attrs:{"placeholder":"Search Inventory","type":"text","disabled":_vm.loading || _vm.backend_searching || !_vm.edit,"debounce":"500"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('div',{},[(!_vm.loading)?_c('ul',{staticClass:"list-group list-height-fix"},_vm._l((_vm.inventoryList),function(inventory,index){return _c('li',{key:index,staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex w-100 align-items-center"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('strong',[_vm._v("["+_vm._s(_vm._f("uppercase")(inventory.adserver))+"]")]),_vm._v(" "+_vm._s(inventory.name)+" "),(_vm.isIncluded(inventory) || _vm.isExcluded(inventory))?_c('small',{staticClass:"w-100 d-flex",staticStyle:{"height":"19px"}},[_c('CloseTag',{attrs:{"label":_vm.isIncluded(inventory)
                              ? 'Included'
                              : _vm.isExcluded(inventory)
                                ? 'Excluded'
                                : '',"title":"Remove from selected list","variant":_vm.isIncluded(inventory)
                              ? 'success'
                              : _vm.isExcluded(inventory)
                                ? 'danger'
                                : 'secondary'},on:{"clicked":function($event){return _vm.removeSelectionById(inventory.id)}}})],1):_vm._e(),_c('br'),_c('small',{staticClass:"mt-2"},[(inventory.is_fallback)?_c('b-badge',{attrs:{"variant":"warning","small":""}},[_vm._v(" Fallback Adunit ")]):_vm._e(),(inventory.fallback_inventory_id)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" Has Fallback ")]):_vm._e()],1)])]),_c('div',{staticClass:"ml-auto"},[_c('a',{staticClass:"ml-auto",class:{
                        'text-primary': _vm.edit,
                        'text-secondary': !_vm.edit,
                      },attrs:{"href":"#","title":"Include"},on:{"click":function($event){$event.preventDefault();return _vm.select(inventory)}}},[_c('i',{staticClass:"las la-check la-lg"})]),_c('a',{staticClass:"mx-2",class:{
                        'text-danger': _vm.edit,
                        'text-secondary': !_vm.edit,
                      },attrs:{"href":"#","title":"Exclude"},on:{"click":function($event){$event.preventDefault();return _vm.select(inventory, false)}}},[_c('i',{staticClass:"las la-ban la-lg"})]),(!_vm.filter.length)?_c('a',{staticClass:"text-secondary ml-auto",attrs:{"href":"#","title":"Toggle"},on:{"click":function($event){$event.preventDefault();return _vm.open_child(inventory.id)}}},[_c('i',{staticClass:"las la-angle-down la-lg"})]):_vm._e()])]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":`child-${inventory.id}`,"role":"tabpanel","accordion":`child-${inventory.id}`}},[(_vm.load_tracker[inventory.id] && !_vm.load_tracker[inventory.id].loading)?_c('ul',{staticClass:"list-group"},_vm._l((_vm.get_child(inventory.id)),function(sub_lvl){return _c('li',{key:sub_lvl.id,staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex w-100"},[_c('div',[_c('strong',[_vm._v("["+_vm._s(_vm._f("uppercase")(sub_lvl.adserver))+"]")]),_vm._v(" "+_vm._s(sub_lvl.name)+" "),(_vm.isIncluded(sub_lvl) || _vm.isExcluded(sub_lvl))?_c('small',{staticClass:"w-100 d-flex",staticStyle:{"height":"19px"}},[_c('CloseTag',{attrs:{"label":_vm.isIncluded(sub_lvl)
                                    ? 'Included'
                                    : _vm.isExcluded(sub_lvl)
                                      ? 'Excluded'
                                      : '',"title":"Remove from selected list","variant":_vm.isIncluded(sub_lvl)
                                    ? 'success'
                                    : _vm.isExcluded(sub_lvl)
                                      ? 'danger'
                                      : 'secondary'},on:{"clicked":function($event){return _vm.removeSelectionById(sub_lvl.id)}}})],1):_vm._e(),_c('br'),_c('small',{staticClass:"mt-2"},[(sub_lvl.is_fallback)?_c('b-badge',{attrs:{"variant":"warning","small":""}},[_vm._v(" Fallback Adunit ")]):_vm._e(),(sub_lvl.fallback_inventory_id)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" Has Fallback ")]):_vm._e()],1)]),_c('div',{staticClass:"ml-auto"},[_c('a',{staticClass:"ml-auto",class:{
                                'text-primary': _vm.edit,
                                'text-secondary': !_vm.edit,
                              },attrs:{"href":"#","title":"Include"},on:{"click":function($event){$event.preventDefault();return _vm.select(sub_lvl)}}},[_c('i',{staticClass:"las la-check la-lg"})]),_c('a',{staticClass:"mx-2",class:{
                                'text-danger': _vm.edit,
                                'text-secondary': !_vm.edit,
                              },attrs:{"href":"#","title":"Exclude"},on:{"click":function($event){$event.preventDefault();return _vm.select(sub_lvl, false)}}},[_c('i',{staticClass:"las la-ban la-lg"})]),(!_vm.filter.length)?_c('a',{staticClass:"text-secondary ml-auto",attrs:{"href":"#","title":"Toggle"},on:{"click":function($event){$event.preventDefault();return _vm.open_child(sub_lvl.id)}}},[_c('i',{staticClass:"las la-angle-down la-lg"})]):_vm._e()])]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":`child-${sub_lvl.id}`,"role":"tabpanel","accordion":`child-${sub_lvl.id}`}},[(_vm.load_tracker[sub_lvl.id] && !_vm.load_tracker[sub_lvl.id].loading)?_c('ul',{staticClass:"list-group"},_vm._l((_vm.get_child(sub_lvl.id)),function(sub_child_lvl){return _c('li',{key:`child-${sub_child_lvl.id}`,staticClass:"list-group-item d-flex"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(" "+_vm._s(sub_child_lvl.name)+" "),(_vm.isIncluded(sub_child_lvl) || _vm.isExcluded(sub_child_lvl))?_c('small',{staticClass:"w-100 d-flex",staticStyle:{"height":"19px"}},[_c('CloseTag',{attrs:{"label":_vm.isIncluded(sub_child_lvl)
                                          ? 'Included'
                                          : _vm.isExcluded(sub_child_lvl)
                                            ? 'Excluded'
                                            : '',"title":"Remove from selected list","variant":_vm.isIncluded(sub_child_lvl)
                                          ? 'success'
                                          : _vm.isExcluded(sub_child_lvl)
                                            ? 'danger'
                                            : 'secondary'},on:{"clicked":function($event){return _vm.removeSelectionById(sub_child_lvl.id)}}})],1):_vm._e(),_c('br'),_c('small',[(sub_child_lvl.is_fallback)?_c('b-badge',{attrs:{"variant":"warning","small":""}},[_vm._v(" Fallback Adunit ")]):_vm._e(),(sub_child_lvl.fallback_inventory_id)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" Has Fallback ")]):_vm._e()],1)])]),_c('div',{staticClass:"ml-auto"},[_c('IconAction',{attrs:{"title":"Include","icon":"check","size":"lg","color":_vm.edit ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.select(sub_child_lvl)}}}),_c('IconAction',{attrs:{"title":"Exclude","icon":"ban","size":"lg","color":_vm.edit ? 'danger' : 'secondary'},on:{"click":function($event){return _vm.select(sub_child_lvl, false)}}})],1)])}),0):(
                              _vm.load_tracker[sub_lvl.id] && _vm.load_tracker[sub_lvl.id].loading
                            )?_c('div',[_vm._v(" loading ... ")]):(
                              !_vm.load_tracker[sub_lvl.id] ||
                                (!_vm.load_tracker[sub_lvl.id].loading &&
                                  !_vm.load_tracker[sub_lvl.id].childs)
                            )?_c('div',[_vm._v(" - ")]):_vm._e()])],1)])}),0):(_vm.load_tracker[inventory.id] && _vm.load_tracker[inventory.id].loading)?_c('div',[_vm._v(" loading ... ")]):(
                      !_vm.load_tracker[inventory.id] ||
                        (!_vm.load_tracker[inventory.id].loading && !_vm.load_tracker[inventory.id].childs)
                    )?_c('div',[_vm._v(" - ")]):_vm._e()])],1)}),0):_c('div',{staticClass:"w-100 d-flex justify-content-center p-4"},[_c('div',{staticClass:"spinner-border text-secondary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])])]),_c('b-col',{staticClass:"h-100",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"card border-0"},[_c('div',{staticClass:"w-100 d-flex card-header",staticStyle:{"height":"40px"}},[_c('div',[_vm._v(" "+_vm._s(!_vm.selected_nodes.length ? 'None selected' : `${_vm.selected_nodes.length} selected`)+" ")]),_c('div',{staticClass:"ml-auto"},[_c('b-button',{staticClass:"btn-xs",attrs:{"variant":"secondary","disabled":_vm.loading || !_vm.edit},on:{"click":_vm.removeAll}},[_vm._v(" Clear all ")])],1)]),(!_vm.loading)?_c('div',{staticClass:"list-height-fix"},_vm._l((_vm.selected_nodes),function(selected,index){return _c('b-list-group-item',{key:`selected-${selected.name || '-'}`},[_c('div',{staticClass:"d-flex w-100 align-items-center"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(selected.name))]),_c('div',[_c('span',{staticClass:"badge badge-primary mt-2",class:{
                        'badge-danger': selected.action === 'exclude',
                      }},[_vm._v(_vm._s(selected.action))])])]),_c('div',{staticClass:"ml-auto"},[_c('IconAction',{attrs:{"title":"Remove","icon":"times-circle","size":"lg","color":_vm.edit ? 'danger' : 'secondary'},on:{"click":function($event){return _vm.remove(index)}}})],1)])])}),1):_c('div',{staticClass:"w-100 d-flex justify-content-center p-4"},[_c('div',{staticClass:"spinner-border text-secondary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])])],1)],1)]}}],null,false,2470165965)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }