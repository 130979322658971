import Api from './Api'
import Model from './interface/Model'

export default class CompanyContact extends Model {
  public api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'company_contacts' as string | null,
      plural: 'company_contacts' as string | null,
    },
  }

  public id: string | null = null

  public first_name: string = ''

  public last_name: string = ''

  public salutation: any = null

  public email: string = ''

  public title: string = ''

  public company_id: string | null = null

  public owner_id: string | null = null

  public get name() {
    return `${this.first_name} ${this.last_name}`
  }

  public contacts: any = [
    {
      label: 'Phone',
      value: '',
    },
  ]

  public address: any = {
    line_1: '',
    line_2: '',
    line_3: '',
    line_4: '',
  }

  public email_opt_out: boolean = false

  public archived_at: string | null = null

  public get apiData() {
    return {
      id: this.id,
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      title: this.title,
      company_id: this.company_id,
      contacts: this.contacts,
      address: this.address,
      email_opt_out: this.email_opt_out,
      owner_id: this.owner_id,
      salutation: this.salutation,
      archived_at: this.archived_at,
    }
  }

  public static async getContacts(ids: string[]) {
    let api = new Api()
    return api
      .get('company_contacts/search_ids', { ids })
      .then(response => response)
      .catch(error => error)
  }

  public static salutations = [
    {
      name: 'Mr.',
      value: 'mr',
    },
    {
      name: 'Ms.',
      value: 'ms',
    },
    {
      name: 'Mrs.',
      value: 'mrs',
    },
    {
      name: 'Dr.',
      value: 'dr',
    },
    {
      name: 'None',
      value: null,
    },
  ]
}
