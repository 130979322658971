
import ViewModel from '@/models/ViewModel'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import SelectPicker from '../SelectPicker/SelectPicker.vue'

@Component({
  components: {
    SelectPicker,
  },
})
export default class SpecialFeaturesPicker extends ViewModel {
  @Prop({ default: () => [] })
  public value!: any

  @Prop({ default: () => false })
  public multiple!: boolean

  public local_watch: boolean = false

  public local_value: any = null

  public options: any = [
    {
      name: '90 seconds Ads',
      value: '90S',
    },
    {
      name: '60 seconds Ads',
      value: '60S',
    },
    {
      name: 'DV-DCM 90 seconds',
      value: 'DV90S',
    },
    {
      name: 'DV-DCM 30 seconds',
      value: '30S',
    },
    {
      name: 'DV-DCM 15 seconds',
      value: '15S',
    },
    {
      name: 'Audience Targeting',
      value: 'AT',
    },
    {
      name: '3rd Party Deal',
      value: '3PD',
    },
    // {
    //   name: 'DV-DCM',
    //   value: '3PT',
    // },
  ]

  @Watch('local_value')
  public onLocalValueChange(val: any) {
    if (this.local_watch && val !== null && val.length) {
      this.$emit('input', val)
    }
  }

  @Watch('value')
  public onValueChange(val: any) {
    if (this.local_watch) {
      this.local_value = val
    }
  }

  public mounted() {
    this.local_value = this.value
    this.local_watch = true
  }

  public transmit(e) {
    setTimeout(() => {
      this.$emit('input', this.local_value)
    }, 400)
  }
}
