
import { Component, Prop, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import { AgeLowOptions, AgeHighOptions, TargetOptions } from '@/models/interface/Common'

@Component({
  components: {
    Widget,
    SelectPicker,
    IconAction,
  },
})
export default class DemoTargetBuilder extends Vue {
  @Prop({ required: true })
  private value!: any

  @Prop({ default: false })
  public horizontal!: any

  @Prop({ default: null })
  public viewStyle!: string

  @Prop({ default: 1 })
  public componentVersion!: number

  public view_type: any = 'list'

  public get options() {
    return {
      age_low: AgeLowOptions,
      age_high: AgeHighOptions,
      target: TargetOptions,
    }
  }

  public get cantEdit() {
    return this.$attrs.edit !== 'undefined' && !this.$attrs.edit
  }

  private get localValue() {
    return this.value
  }

  private set localValue(value: any) {
    this.$emit('input', value)
  }

  public mounted() {
    if (this.viewStyle) {
      this.view_type = this.viewStyle
    }
  }

  public remove(index: number) {
    this.localValue.splice(index, 1)
  }

  public add() {
    this.localValue.push({
      age_low: '',
      age_high: '',
      target: '',
    })
  }
}
