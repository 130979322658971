
import { Component, Prop, Watch } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ReportView from '@/models/ReportView'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'

@Component({
  components: {
    SelectPicker,
  },
})
export default class ReportViewPicker extends ViewModel {
  @Prop({ default: true })
  public edit!: boolean;

  @Prop({ default: false })
  public allow_empty!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: false })
  public multiple!: boolean;

  @Prop({ default: '' })
  public company_id!: string;

  @Prop({ default: '' })
  public station_id!: string;

  @Prop({ default: false })
  public create_panel!: boolean;

  @Prop({ default: 'ReportView' })
  public label!: string;

  @Prop({ default: () => null })
  public value!: string | null;

  public local_value: string[] | string | null = [];

  public new_id: string = '';

  public show_sidebar: boolean = false;

  public update: boolean = true;

  public uuid: string = '';

  public busy: boolean = false;

  public options: SelectOption[] = [];

  public label_name: string = '';

  public ready: boolean = false;

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      if (
        !this.value
        || (Array.isArray(this.value) && this.value.length == 0)
      ) {
        this.ready = true
      } else {
        this.busy = true
        ReportView.module
          .searchOptions({
            value: this.value,
          })
          .then(response => {
            this.options = response
            this.busy = false
            this.local_value = this.value
            this.ready = true
          })
      }
    } else {
      this.updateLabel()
    }
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }

  public updateLabel() {
    if (!this.value) {
      this.label_name = 'N/A'
    } else if (!Array.isArray(this.value)) {
      ReportView.module
        .searchOptions({
          value: this.value,
        })
        .then(response => {
          this.label_name = response[0].name
          this.ready = true
        })
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    ReportView.module
      .searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
        owner_id: this.station_id ?? this.company_id,
      })
      .then(response => {
        this.options = response
        this.busy = false
        this.ready = true
      })
  }

  public get can_create() {
    return this.create_panel && this.user.can('report_view', 'edit')
  }

  public get panel_callback(): any {
    return this.layout.side_panel_callback.report_view
  }

  @Watch('local_value')
  public onChangeValue(val: any) {
    this.update = false
    if (val == null) {
      this.$emit('input', null)
    } else {
      this.$emit('input', this.local_value)
    }
  }

  @Watch('value')
  public onChangeProp(val: any) {
    if (this.update) {
      if (val == null) {
        this.local_value = null
      } else {
        this.local_value = this.value
      }
    }
    this.update = true
    this.updateLabel()
  }

  @Watch('panel_callback')
  public onCallback() {
    if (this.uuid == this.layout.side_panel_id && this.panel_callback) {
      this.local_value = this.panel_callback
      this.layout.clearSidePanelCallback()
    }
  }

  @Watch('company_id')
  public onChangeCompany() {
    this.searchOptions()
  }

  @Watch('station_id')
  public onChangeStation() {
    this.searchOptions()
  }

  public toggleSideBar() {
    this.layout.showSidePanel({
      name: 'report_view',
      id: this.uuid,
      data: {
        company_id: this.company_id,
        station_id: this.station_id,
      },
    })
  }
}
