
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import MediaPlan from '@/models/MediaPlan'
import MediaPlanItem from '@/models/MediaPlanItem'
import { currencyMask } from '@/models/interface/Masks'
import moment from 'moment'

@Component({
  components: {
    FormInput,
    DataTable,
  },
})
export default class MediaPlanSchedules extends ViewModel {
  @Ref()
  public footValidation!: any

  @Prop()
  public value!: any

  @Prop()
  public rules!: any

  @Prop()
  public parentModel!: any

  @Prop({ default: true })
  public readOnly!: boolean

  @Prop({ default: false })
  public simpleTable!: boolean

  @Prop()
  public startDate!: any

  @Prop()
  public endDate!: any

  @Prop()
  public revenue!: any

  @Prop()
  public blockFromEditing!: any

  @Prop()
  public hideFields!: any

  @Prop()
  public modelType!: any

  public fields: any = [
    {
      key: 'month',
      label: '',
      sortable: true,
      show: true,
      class: 'text-center align-middle text-capitalize',
      thStyle: { width: '10rem' },
    },
    {
      key: 'billing_schedule',
      label: 'Billing Schedule',
      sortable: true,
      show: true,
      class: 'text-center align-middle',
    },
    {
      key: 'cost_schedule',
      label: 'Cost Schedule',
      sortable: true,
      show: true,
      class: 'text-center align-middle',
    },
    {
      key: 'revenue_schedule',
      label: 'Revenue Schedule',
      sortable: true,
      show: true,
      class: 'text-center align-middle',
    },
  ]

  public ready: boolean = false

  // this will be used by the line item modeType
  public get media_plan(): MediaPlan {
    return this.parentModel
  }

  public set media_plan(value: any) {
    if (!this.parentModel) return
    this.$emit('update:parentModel', value)
  }

  public get local_value() {
    return this.value
  }

  public set local_value(value: any) {
    this.$emit('input', value)
  }

  public get show_fields() {
    return this.fields.filter((field: any) => {
      if (this.hideFields && this.hideFields.includes(field.key)) {
        return false
      }
      return field.show
    })
  }

  public get items() {
    let start: any = ''
    let end: any = ''

    if (this.modelType && this.modelType === 'line_item') {
      start = moment(this.local_value.formStartAt).subtract(1, 'month').startOf('month')
      end = moment(this.local_value.formEndAt).add(1, 'month').endOf('month')
    } else {
      // media plan in v-model
      let items = this.local_value.line_items.filter((item: any) => item.product === 'ccl')
      let months: moment.Moment[] = items.map((item: any) => moment(item.start_at))
      months = months.concat(items.map((item: any) => moment(item.end_at)))

      start = moment.min(months).subtract(1, 'month').startOf('month')
      end = moment.max(months).add(1, 'month').endOf('month')
    }

    let ret: string[] = []

    while (start.isBefore(end)) {
      ret.push(this.replaceStr(start.format('MMM, YYYY')))
      start.add(1, 'month')
    }

    return ret
  }

  public get masks() {
    return {
      currency: currencyMask,
    }
  }

  public buildBillingScheduleData(month_rows: any) {
    if (!Object.keys(this.local_value.billing_schedule).length) {
      this.local_value.billing_schedule = { ...month_rows }
    }
  }

  public buildRevenueScheduleData(month_rows: any) {
    if (!Object.keys(this.local_value.revenue_schedule).length) {
      this.local_value.revenue_schedule = { ...month_rows }
    }
  }

  public buildCostScheduleData(month_rows: any) {
    // cost_schedule only exists in the Media Plan
    if (
      this.local_value.hasOwnProperty('cost_schedule')
      && !Object.keys(this.local_value.cost_schedule).length
    ) {
      for (let rev in month_rows) {
        this.local_value.cost_schedule[rev] = 0
      }
    }
  }

  public mounted() {
    const cleared = this.items.reduce((acc: any, month: any) => {
      let cleared_name = this.replaceStr(month.toLowerCase())
      acc[cleared_name] = 0
      return acc
    }, {})

    this.buildBillingScheduleData(cleared)
    this.buildRevenueScheduleData(cleared)
    this.buildCostScheduleData(cleared)

    this.ready = true
  }

  public replaceStr(name: string, clear_name: boolean = false) {
    if (clear_name) return name.replace('_', ', ')
    name = name.toLowerCase()
    return name.replace(',', '').replace(' ', '_').toLowerCase()
  }

  public emitErrors(errors: any) {
    this.$emit('errors', errors)
    return ''
  }

  public canEdit(name: string) {
    if (!this.blockFromEditing) return true
    return !this.blockFromEditing.includes(name)
  }

  public get_data_from_model(name: string) {}
}
